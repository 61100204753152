import { makeAutoObservable, reaction, runInAction, toJS } from "mobx";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IDropdownCategory, IDropdownPage, IDropdownSitemap, ISitemap, ISitemapPayload, ISitemapTree } from "../models/sitemap";
import agent from "../api/agent";

export class SitemapStore {
  sitemapGrid: DataGridResult<ISitemap[]> = new DataGridResult([], 0);
  sitemapGridLoading = false;

  dropdownSitemap: IDropdownSitemap[] = []
  dropdownSitemapLoading = false
  
  dropdownPage: IDropdownPage[] = []
  dropdownPageLoading = false

  dropdownCategory: IDropdownCategory[] = []
  dropdownCategoryLoading = false
  
  deleteSitemapLoading = false

  editedSitemap: ISitemap | ISitemapTree | null = null;
  
  sitemapTree: ISitemapTree[] = []
  sitemapTreeLoading: boolean = false

  sortedSitemap: ISitemap | ISitemapTree | null = null
  
  params = {
    pageSize: 25,
    currentPage: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  getSitemapTree = async () => {
    this.sitemapTreeLoading = true;
    try {
      const res = await agent.Sitemap.getTree()

      this.sitemapTree = res
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => this.sitemapTreeLoading = false)
    }
  }

  getSitemapGrid = async () => {
    this.sitemapGridLoading = true;
    try {
      const res = await agent.Sitemap.grid(this.params);

      runInAction(() => this.sitemapGrid = res);
    } finally {
      runInAction(() => this.sitemapGridLoading = false)
    }
  };

  getDropdownSitemap = async () => {
    this.dropdownSitemapLoading = true
    try {
      const res = await agent.Sitemap.dropdownSitemap()

      runInAction(() => this.dropdownSitemap = res)
    } finally {
      runInAction(() => this.dropdownSitemapLoading = false)
    }
  }

  getDropdownPage = async () => {
    this.dropdownPageLoading = true
    try {
      const res = await agent.Sitemap.dropdownPage()

      runInAction(() => this.dropdownPage = res)
    } finally {
      runInAction(() => this.dropdownPageLoading = false)
    }
  }
  getDropdownCategory = async () => {
    this.dropdownCategoryLoading = true
    try {
      const res = await agent.Sitemap.dropdownCategory()

      runInAction(() => this.dropdownCategory = res)
    } finally {
      runInAction(() => this.dropdownCategoryLoading = false)
    }
  }

  createSitemap = async (body: ISitemapPayload) => {
    return await agent.Sitemap.create(body)
  }
  
  deleteSitemap = async (id: string) => {
    this.deleteSitemapLoading = true;
    try {
      return await agent.Sitemap.delete(id)
    } finally {
      runInAction(() => this.deleteSitemapLoading = false)
    }
  }

  updateSitemap = async (id: string, body: ISitemapPayload) => {
    await agent.Sitemap.update(id, body)
  }

  changeOrderLoading = false

  changeOrder = async (body: { id: string, orderNumber: number }) => {
    this.changeOrderLoading = true;
    try {
      await agent.Sitemap.changeOrder(body)
    } finally {
      this.changeOrderLoading = false;
    }
  }

  setEditedSitemap = async (sitemap: ISitemap | ISitemapTree | null) => {
    this.editedSitemap = sitemap
  }

  setSortedSitemap = async (sitemap: ISitemap | ISitemapTree | null) => {
    this.sortedSitemap = sitemap
  }

  setParams = (key: keyof typeof this.params, value: number) => {
    this.params[key] = value;
  }
}
