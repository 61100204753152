import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useFormik } from "formik";
import * as yup from "yup";
import { toJS } from "mobx";
import { ISitemapTree } from "../../app/models/sitemap";

// function moveElement(array: any[], fromIndex: number, toIndex: number) {
//   // if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
//   //   console.error('Indeks tidak valid');
//   //   return array;
//   // }

//   const element = array[fromIndex];
//   array.splice(fromIndex, 1); // Hapus elemen dari posisi asli
//   array.splice(toIndex, 0, element); // Sisipkan elemen ke posisi baru

//   return array
// }

// function findOldIndexInTree(tree: ISitemapTree[], idToFind: string): number {
//   for (let i = 0; i < tree.length; i++) {
//     if (tree[i].id === idToFind) {
//       return i; // Found at this level
//     }
    
//     // If it has children, search recursively
//     if (tree[i].child) {
//       const childIndex = findOldIndexInTree((tree[i] as any).child, idToFind);
//       if (childIndex !== -1) {
//         return childIndex; // Found in the children
//       }
//     }
//   }
  
//   return -1; // Not found
// }

// function moveItemByOrder(
//   tree: ISitemapTree[],
//   itemId: string,
//   newOrder: number
// ): ISitemapTree[] {
//   let itemToMove: ISitemapTree | null = null;
//   let parentNode: ISitemapTree | null = null;

//   // Step 1: Find the item and its parent
//   function findItem(tree: ISitemapTree[], idToFind: string): void {
//     for (const node of tree) {
//       if (node.id === idToFind) {
//         itemToMove = node;
//       } else if (node.child) {
//         findItem(node.child, idToFind);
//       }
      
//       if (itemToMove) {
//         parentNode = node;
//       }
//     }
//   }

//   findItem(tree, itemId);

//   if (!itemToMove || !parentNode) return tree; // Item not found

//   // Step 2: Remove item from current parent
//   const currentIndex = (parentNode as any).child?.findIndex((child: any) => child.id === itemId);
  
//   if (currentIndex !== undefined && currentIndex !== -1) {
//     (parentNode as any).child.splice(currentIndex, 1);
//   }

//   // Step 3: Find new index based on newOrder
//   const targetIndex = (parentNode as any).child?.findIndex((child: any) => child.orderNumber > newOrder) ?? -1;

//   // Step 4: Insert item in the new position
//   if (targetIndex !== -1) {
//     (parentNode as any).child.splice(targetIndex, 0, itemToMove);
//   } else {
//     // If targetIndex is -1, append to the end
//     (parentNode as any).child.push(itemToMove);
//   }

//   // Optional: Sort children by orderNumber if needed
//   if ((parentNode as any).child) {
//     (parentNode as any).child.sort((a: any, b: any) => a.orderNumber - b.orderNumber);
//   }

//   return tree;
// }

const SitemapSortModal = () => {
  const {
    getSitemapTree,
    sitemapTree,
    changeOrder,
    changeOrderLoading,
    setSortedSitemap,
    sortedSitemap
  } = useStore().sitemapStore;

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    resetForm,
    isSubmitting,
  } = useFormik({
    validationSchema: yup.object({
      orderNumber: yup.number()
        .required('Urutan tidak boleh kosong')
        .min(1, 'Urutan tidak boleh kurang dari 1')
        // .max(sitemapTree.length, 'Urutan tidak boleh lebih dari jumlah item ' + sitemapTree.length)
    }),
    initialValues: {
      orderNumber: (sortedSitemap as any)?.orderNumber ?? undefined,
    },
    onSubmit: async (values) => {
      if (!sortedSitemap) return;
      
      const { orderNumber } = values
      // const updatedTree = moveItemByOrder(sitemapTree, sortedSitemap.id, orderNumber)
      
      // const sorted = moveElement(sitemapTree, oldOrderNumber, orderNumber - 1)
      // console.log("sorted", toJS(updatedTree))
      // const payload = sorted?.map(i => ({
      //   id: i.id
      // }))

      // console.log("heere")

      // if (!payload) throw new Error('Payload is not found');
      
      await changeOrder({
        id: sortedSitemap.id,
        orderNumber
      })
      await getSitemapTree()

      resetForm()
      setSortedSitemap(null)
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      sx={{ ".MuiPaper-root": { maxWidth: 500, width: "100%" } }}
      open={!!sortedSitemap}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle>
          <Typography variant="h3" fontSize={20} fontWeight={600} color="black">
            Pindahkan item ke urutan:
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ pt: 2, gap: 1 }}>
            <TextField
              type="number"
              error={!!errors.orderNumber}
              helperText={errors.orderNumber as any}
              value={values.orderNumber}
              name='orderNumber'
              label="Urutan ke"
              onChange={handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => {
              setSortedSitemap(null);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default observer(SitemapSortModal);
