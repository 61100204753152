import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  pointerWithin,
  DragOverEvent,
  Active,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { Box } from "@mui/material";
import Panel from "./Panel";
import { DraggableItemOverlay } from "./DraggableItemOverlay";
import { IComponent } from "../../../app/models/component";
import { DRAGGABLE, DROPPABLE_AREA, SORTABLE_ITEM } from "../helper/constant";
import { nanoid } from "nanoid";
import { useStore } from "../../../app/stores/store";
import { IDndTemplate } from "../../../app/models/dndTemplate";
import { Droppable } from "./Droppable";
import { SortableItemOverlay } from "./SortableItemOverlay";

export interface IItemValue extends IComponent {
  itemId: string;
}

export function DNDController({
  panelOpen,
  setPanelOpen,
}: {
  panelOpen: boolean;
  setPanelOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [activeItem, setActiveItem] = useState<Active | null>(null);
  const { setDndItems, addDndItem, dndItems } = useStore().dndTemplateStore;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // activationConstraint: { delay: 200, distance: 0 },
    }),
    useSensor(MouseSensor, {
      // activationConstraint: { delay: 200, distance: 0 },
    }),
    useSensor(TouchSensor, {
      // activationConstraint: { delay: 200, distance: 0 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    if (!!dndItems.find(item => active.id === item.id)) return;
    
    setActiveItem(active);
  }

  function handleDragCancel() {
    setActiveItem(null);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    // add item
    if (
      (active.data.current?.dndType === DRAGGABLE &&
      over?.data.current?.dndType === DROPPABLE_AREA) ||
      (active.data.current?.dndType === DRAGGABLE &&
        over?.data.current?.dndType === SORTABLE_ITEM)
    ) {
      if (!active.data.current) return;
      const { dndType, ...currentData } = active.data.current;
      const itemId = nanoid();

      addDndItem({ ...currentData, itemId } as IDndTemplate);
    }

    // sort item
    if (active.data.current?.dndType === SORTABLE_ITEM) {
      if (!over) return;
      
      if (active.id !== over?.id) {
        const oldIndex = dndItems
          .map((item) => item.itemId)
          .indexOf(active.id as any);
        const newIndex = dndItems
          .map((item) => item.itemId)
          .indexOf(over.id as string);

        const result = arrayMove(dndItems, oldIndex, newIndex);

        setDndItems(result);
      }
    }

    setActiveItem(null);
  }

  const handleDraOver = (event: DragOverEvent) => {
    console.log(event.over?.data.current?.dndType);
  };

  return (
    <Box>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        onDragOver={handleDraOver}
        collisionDetection={pointerWithin}
      >
        <Droppable items={dndItems} />
        {activeItem ? (
          activeItem.data.current?.dndType === DRAGGABLE ? (
            <DraggableItemOverlay
              item={activeItem.data.current as IItemValue}
            />
          ) : activeItem.data.current?.dndType === SORTABLE_ITEM ? (
            <SortableItemOverlay item={activeItem.data.current as IItemValue} />
          ) : null
        ) : null}
        <Panel setIsOpen={setPanelOpen} open={panelOpen} onClose={() => setPanelOpen(false)} />
      </DndContext>
    </Box>
  );
}
