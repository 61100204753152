import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { history } from "../..";
import { IExample } from "../models/example";
import {
  IAccountInfo,
  IAccountLoginValues,
  IAccountRegisterValues,
  IChangePasswordValue,
  IForgotPasswordValue,
} from "../models/account";
import { store } from "../stores/store";
import { IUser, IUserDetail, IUserPhoto } from "../models/user";
import { DataGridResult, IDataGridParams } from "../models/materialUI/dataGrid";
import { ApprovalResponseType, PublishStatus, Roles } from "../config/enum";
import { IComponent } from "../models/component";
import {
  ITemplate,
  ITemplateDetail,
  ITemplateParams,
  ITemplatePayload,
} from "../models/template";
import { IPage, IPageDetail, PagePayload } from "../models/page";
import {
  IContentType,
  IContentTypeDetail,
  IContentTypePayload,
} from "../models/contentType";
import {
  IDropdownCategory,
  IDropdownPage,
  IDropdownSitemap,
  ISitemap,
  ISitemapPayload,
  ISitemapTree,
} from "../models/sitemap";
import { AddMediaResponse, IMedia, IMediaParams } from "../models/media";
import { ILanguage } from "../models/language";
import {
  PageApproveRejectTypeValues,
  IPageApproval,
  IPageApprovalLog,
} from "../models/pageApproval";
import {
  ContentTypeApproveRejectTypeValues,
  IContentTypeApproval,
  IContentTypeApprovalLog,
} from "../models/contentTypeApproval";
import {
  IPagePublish,
  IPagePublishDetail,
  IPagePublishPayload,
  IPageUnpublishPayload,
} from "../models/pagePublish";
import {
  IContentTypeMasterDetail,
  IContentTypeMasterDropdown,
} from "../models/contentTypeMaster";
import {
  IContentTypePublish,
  IContentTypePublishDetail,
  IContentTypePublishPayload,
} from "../models/contentTypePublish";
import {
  ICategory,
  ICategoryDropdown,
  ICategoryPayload,
} from "../models/category";
import { ITimeline, TimelinePayload } from "../models/timeline";
import { IDashboardContentType, IDashboardPage } from "../models/dashboard";
import ModalStore from "../stores/helper/modalStore";
import { ISession } from "../models/session";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    // if (process.env.NODE_ENV === 'development') await sleep(1500);
    response = readResponseHeader(response);
    return response;
  },
  (error: AxiosError<any>) => {
    const { data, status, config } = error.response!;

    if (config.url !== "/account/login") {
      switch (status) {
        case 400:
          console.log(data);
          if (typeof data === "string") {
            store.snackbarStore.show("error", data);
          }
          if (config.method === "get" && data.errors.hasOwnProperty("id")) {
            history.push("/not-found");
          }
          if (data.errors) {
            const modalStateErrors = [];
            for (const key in data.errors) {
              if (data.errors[key]) {
                modalStateErrors.push(" " + data.errors[key]);
              }
            }
            // throw modalStateErrors.flat();
            store.snackbarStore.show("error", modalStateErrors.join());
          }
          break;
        case 401:
            store.snackbarStore.show('error', 'Error 401 (Unauthorized)');
            break;
        case 403:
          store.snackbarStore.show("error", "Error 403 (Forbidden)");
          break;
        // case 404:
        //     history.push('/not-found');
        //     break;
        case 500:
          store.commonStore.setServerError(data);
          history.push("/server-error");
          break;
      }
    }

    return Promise.reject(error);
  }
);

const readResponseHeader = (response: AxiosResponse): AxiosResponse => {
  if (response.headers["rowcount"]) {
    // data grid result
    response.data = new DataGridResult(
      response.data,
      parseInt(response.headers["rowcount"])
    );
    return response as AxiosResponse<DataGridResult<any>>;
  }
  return response;
};

const handleBlobResponse = (response: AxiosResponse<Blob>) => {
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  const filename = response.headers["content-disposition"].split('"')[1];
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const responseBody = <T,>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T,>(url: string, body?: {}) =>
    axios.get<T>(url, body).then(responseBody),
  getFile: (url: string, body?: AxiosRequestConfig<any>) =>
    axios
      .get<Blob>(url, { ...body, responseType: "blob" })
      .then((response) => handleBlobResponse(response)),
  post: <T,>(url: string, body?: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T,>(url: string, body?: {}) =>
    axios.put<T>(url, body).then(responseBody),
  del: <T,>(url: string, body?: {}) =>
    axios.delete<T>(url, { data: body }).then(responseBody),
  postFile: <T,>(url: string, body?: {}) =>
    axios
      .post<T>(url, body, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(responseBody),
  putFile: <T,>(url: string, body?: {}) =>
    axios
      .put<T>(url, body, { headers: { "Content-Type": "multipart/form-data" } })
      .then(responseBody),
};

const Account = {
  current: () => requests.get<IAccountInfo>("/account"),
  login: (user: IAccountLoginValues) =>
    requests.post<IAccountInfo>("/account/login", user),
  register: (user: IAccountRegisterValues) =>
    requests.post<IAccountInfo>("/account/register", user),
  forgotPassword: (payload: IForgotPasswordValue) =>
    requests.put("/user/forgotPassword", payload),
  changePassword: (username: string, payload: IChangePasswordValue) =>
    requests.put(`/user/${username}/updatePassword`, payload),
  refreshToken: (token: string, refreshToken: string) => requests.post<IAccountInfo>(`/account/refresh-access-token`, { token, refreshToken })
};

const Session = {
  get: () => requests.get<ISession>('/session'),
  edit: (body: ISession) => requests.post('/session', body)
}

const User = {
  list: (params: URLSearchParams) =>
    requests.get<DataGridResult<IUser[]>>("/user", { params }),
  detail: (username: string) => requests.get<IUserDetail>(`/user/${username}`),
  create: (user: IUser) => requests.post<void>("/user", user),
  edit: (user: IUser) => requests.put<void>(`/user/${user.username}`, user),
  delete: (username: string) => requests.del<void>(`/user/${username}`),
  upload: (data: IUserPhoto) =>
    requests.postFile<void>(`/user/${data.username}/photo`, data.file),
  addRole: (username: string, role: Roles) =>
    requests.post<void>(`/user/${username}/role`, { name: role }),
  removeRole: (username: string, role: Roles) =>
    requests.del<void>(`/user/${username}/role`, { name: role }),
  resetPass: (username: string) =>
    requests.put<void>(`/user/${username}/reset`),
};

const Role = {};

const Example = {
  list: (params: URLSearchParams) =>
    requests.get<IExample[]>("/example", { params }), // name must exactly "params"
  listGrid: (params: URLSearchParams) =>
    requests.get<DataGridResult<IExample[]>>("/example/grid", { params }), // because it's a field called "params"
  detail: (id: string) => requests.get<IExample>(`/example/${id}`),
  create: (example: IExample) => requests.post<void>("/example", example),
  edit: (example: IExample) =>
    requests.put<void>(`/example/${example.id}`, example),
  delete: (id: string) => requests.del<void>(`/example/${id}`),
};

const Component = {
  list: () => requests.get<IComponent[]>("/components"),
};

const Template = {
  listGrid: (params: ITemplateParams) =>
    requests.get<DataGridResult<ITemplate[]>>("/templates/grid", { params }),
  list: (search?: string) =>
    requests.get<ITemplate[]>(`/templates`, { params: { search } }),
  create: (body: ITemplatePayload) => requests.post("/templates", body),
  delete: (templateId: string) => requests.del(`/templates/${templateId}`),
  duplicate: (templateId: string) =>
    requests.post("/templates/duplicate", { templateId }),
  detail: (templateId: string) =>
    requests.get<ITemplateDetail>(`/templates/${templateId}`),
  update: (templateId: string, body: ITemplatePayload) =>
    requests.put(`/templates/${templateId}`, body),
};

const Pages = {
  listGrid: (params: IDataGridParams) =>
    requests.get<DataGridResult<IPage[]>>("/pages/grid", { params }),
  detail: (pageId: string, languageId: string) =>
    requests.get<IPageDetail>(`/pages/${pageId}/${languageId}`),
  create: (body: PagePayload) => requests.post<string>("/pages", body),
  update: (pageId: string, body: PagePayload) =>
    requests.put(`/pages/${pageId}`, body),
  delete: (pageId: string) => requests.del(`/pages/${pageId}`),
};

const PageApproval = {
  listGrid: (params: IDataGridParams) =>
    requests.get<DataGridResult<IPageApproval[]>>(`/approval/page/grid`, {
      params,
    }),
  logListGrid: (pageId: string, params: IDataGridParams) =>
    requests.get<DataGridResult<IPageApprovalLog[]>>(
      `/approval/page/${pageId}/log/grid`,
      { params }
    ),
  approveReject: (
    status: ApprovalResponseType,
    body: Omit<PageApproveRejectTypeValues, "status">
  ) => requests.post(`/pages/${status}`, body),
};

const ContentType = {
  list: (contentTypeName: string) =>
    requests.get<IContentType[]>(`/contentType/${contentTypeName}`),
  grid: (contentTypeName: string, params: IDataGridParams) =>
    requests.get<DataGridResult<IContentType[]>>(
      `/ContentType/grid/${contentTypeName}`,
      { params }
    ),
  create: (body: IContentTypePayload) =>
    requests.post<string>(`/contentType`, body),
  update: (id: string, body: IContentTypePayload) =>
    requests.put(`/contentType/${id}`, body),
  detail: (contentTypeId: string, languageId: string) =>
    requests.get<IContentTypeDetail>(
      `/contentType/${contentTypeId}/${languageId}`
    ),
  delete: (contentTypeId: string) =>
    requests.del(`/contentType/${contentTypeId}`),
  categoryList: (contentTypeName: string) =>
    requests.get<ICategory[]>(`/contentType/categories/${contentTypeName}`),
};

const ContentTypeMaster = {
  detail: (contentTypeId: string) =>
    requests.get<IContentTypeMasterDetail>(
      `/master/contentType/${contentTypeId}`
    ),
  dropdown: () =>
    requests.get<IContentTypeMasterDropdown[]>(`/master/contentType`),
};

const ContentTypeApproval = {
  listGrid: (params: IDataGridParams) =>
    requests.get<DataGridResult<IContentTypeApproval[]>>(
      `/approval/contentType/grid`,
      { params }
    ),
  logListGrid: (contentTypeValueId: string, params: IDataGridParams) =>
    requests.get<DataGridResult<IContentTypeApprovalLog[]>>(
      `/approval/${contentTypeValueId}/log/grid`,
      { params }
    ),
  approveReject: (body: ContentTypeApproveRejectTypeValues) =>
    requests.post(`/contentType/${body.status}`, body),
};

const Sitemap = {
  dropdownSitemap: () => requests.get<IDropdownSitemap[]>("/dropdown/siteMap"),
  dropdownPage: () => requests.get<IDropdownPage[]>("/dropdown/page"),
  dropdownCategory: () => requests.get<IDropdownCategory[]>("/dropdown/category"),
  create: (body: ISitemapPayload) => requests.post("/sitemap", body),
  grid: (params: IDataGridParams) =>
    requests.get<DataGridResult<ISitemap[]>>("/siteMap/grid", { params }),
  changeOrder: (body: { id: string, orderNumber: number }) =>
    requests.put(`/Sitemap/ChangeOrderNumber`, body),
  delete: (sitemapId: string) => requests.del(`/sitemap/${sitemapId}`),
  update: (sitemapId: string, body: ISitemapPayload) =>
    requests.put(`/sitemap/${sitemapId}`, body),
  getTree: () => requests.get<ISitemapTree[]>(`/sitemap/tree`),
};

const Media = {
  create: (body: FormData) =>
    requests.postFile<AddMediaResponse>("/medias", body),
  edit: (id: string, body: FormData) => requests.putFile(`/medias/${id}`, body),
  delete: (id: string) => requests.del(`/medias/${id}`),
  grid: (type: "FILE" | "IMAGE" | "VIDEO", params: IMediaParams) =>
    requests.get<DataGridResult<IMedia[]>>(`/medias/grid/${type}`, { params }),
};

const Language = {
  list: () => requests.get<ILanguage[]>("/languages"),
};

const PagePublish = {
  getDetail: (pageId: string) =>
    requests.get<IPagePublishDetail>(`/pages/publisher/${pageId}`),
  getGrid: (params: IDataGridParams) =>
    requests.get<DataGridResult<IPagePublish[]>>(`pages/publisher/grid`, {
      params,
    }),
  publish: (status: PublishStatus, body: IPagePublishPayload) =>
    requests.post(`/pages/publish/${status}`, body),
  unpublish: (pageId: string, body: IPageUnpublishPayload) =>
    requests.post(`/pages/${pageId}/unpublished`, body),
};

const ContentTypePublish = {
  getDetail: (pageId: string) =>
    requests.get<IContentTypePublishDetail>(`/contentType/publisher/${pageId}`),
  getGrid: (params: IDataGridParams) =>
    requests.get<DataGridResult<IContentTypePublish[]>>(
      `/contentType/publisher/grid`,
      { params }
    ),
  publish: (status: PublishStatus, body: IContentTypePublishPayload) =>
    requests.post(`/contentType/publish/${status}`, body),
  unpublish: (pageId: string, body: IPageUnpublishPayload) =>
    requests.post(`/contentType/${pageId}/unpublished`, body),
};

const ContentTypeCategory = {
  getGrid: (params: IDataGridParams) =>
    requests.get<DataGridResult<ICategory[]>>("/contentType/category/grid", {
      params,
    }),
  detail: (params: { languageId: string; id: string }) => requests.get<ICategory>(`/contentType/category/${params.id}/${params.languageId}`),
  create: (body: ICategoryPayload) =>
    requests.post(`/contentType/category`, body),
  dropdown: () => requests.get<ICategoryDropdown[]>(`/dropdown/category`),
  update: (id: string, body: ICategoryPayload) =>
    requests.put(`/contentType/category/${id}`, body),
  delete: (id: string) => requests.del(`/contentType/category/${id}`),
};

const Timeline = {
  grid: (params: any) =>
    requests.get<DataGridResult<ITimeline[]>>(`/Timeline/grid`, { params }),
  list: () => requests.get<ITimeline[]>(`/Timeline`),
  detail: (id: string) => requests.get<ITimeline[]>(`/Timeline/${id}`),
  create: (body: TimelinePayload) => requests.post(`/Timeline`, body),
  update: (id: string, body: TimelinePayload) =>
    requests.put(`/Timeline/${id}`, body),
  delete: (id: string) => requests.del(`/Timeline/${id}`),
};

const Dashboard = {
  page: () => requests.get<IDashboardPage>("/dashboard/page"),
  contentType: () =>
    requests.get<IDashboardContentType[]>("/dashboard/contentType"),
};

const agent = {
  Account,
  User,
  Role,
  Example,
  Component,
  Template,
  Pages,
  ContentType,
  Sitemap,
  Media,
  Language,
  PageApproval,
  ContentTypeApproval,
  PagePublish,
  ContentTypeMaster,
  ContentTypePublish,
  ContentTypeCategory,
  Timeline,
  Dashboard,
  Session,
};

export default agent;
