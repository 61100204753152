import { makeAutoObservable, toJS } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { Roles } from "../config/enum";
import { IAccountInfo, IAccountLoginValues, IAccountRegisterValues, IChangePasswordValue, IForgotPasswordValue } from "../models/account";
import { store } from "./store";

export default class AccountStore {
    account: IAccountInfo | null = null;
    
    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.account;
    }

    isInRole = (roles: Roles[]) => {
        const length = this.account?.roles?.filter((item) => roles.includes(item)).length;
        return !!length;
    }

    refreshToken = async () => {
        try {
            const token = window.localStorage.getItem('jwt')
            const refreshToken = window.localStorage.getItem('jwtRefresh')
    
            if (token && refreshToken) {
                return await agent.Account.refreshToken(token, refreshToken)
            }
        } catch(e) {
            throw e;
        }
    }

    login = async (creds: IAccountLoginValues) => {
        try {
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token, user.refreshToken);
            this.setUser(user);
            history.push('/');
        } catch (error) {
            throw error;
        }
    }

    logout = async () => {
        store.commonStore.setToken(null, null);
        this.setUser(null);
        history.push('/');
    }

    register = async (creds: IAccountRegisterValues) => {
        try {
            const user = await agent.Account.register(creds);
            store.commonStore.setToken(user.token, user.refreshToken);
            this.setUser(user);
            history.push('/');
        } catch (error) {
            throw error;
        }
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            this.setUser(user);
        } catch (error) {
            console.log(error);
        }
    }

    private setUser = (user: IAccountInfo | null) => {
        this.account = user;
        if (this.account) this.account.photo = user?.photo ?? '/assets/user.png'; // utk handle logout
    }

    forgotPassword = async (payload: IForgotPasswordValue) => {
        return await agent.Account.forgotPassword(payload)
    }

    changePassword = async (payload: IChangePasswordValue) => {
        if (!this.account?.username) return 
        
        return await agent.Account.changePassword(this.account?.username, payload)
    }

    setImage = (photo: string) => {
        if (this.account) this.account.photo = photo;
    }

    get isSuperAdmin() {
        return this.account?.roles.includes(Roles.Superadmin);
    }

    get isCreator() {
        return this.account?.roles.includes(Roles.Creator);
    }

    get isPublisher() {
        return this.account?.roles.includes(Roles.Publisher);
    }

    get isApprover() {
        return this.account?.roles.includes(Roles.Approver);
    }
}
