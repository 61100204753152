import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import * as yup from 'yup';
import { useStore } from "../../app/stores/store";
import CategorySelect from "./CategorySelect";
import { ICategory, ICategoryPayload } from "../../app/models/category";
import SelectLanguage from "../page/components/SelectLanguage";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

interface CreateCategoryModalProps {
  edited?: ICategory;
  defaultParentId?: string;
  callback?: () => void;
}

export const CreateCategoryModal = observer(({ edited, defaultParentId, callback }: CreateCategoryModalProps) => {
  const { modalStore, contentTypeCategoryStore } = useStore()
  const { createCategory, getGrid, updateCategory, getCategoryDetail, detail, detailLoading, clearCategoryDetail } = contentTypeCategoryStore
  
  const { getList, language, languageLoading } = useStore().languageStore
  const [lang, setLang] = useState<string>('')
  
  useEffect(() => {
    getList()
  }, [getList])

  useEffect(() => {
    if (language && language.length) {
      if (!lang) {
        setLang(language[0].id)
      }
    };
  }, [language])
  
  useEffect(() => {
    if (edited) getCategoryDetail(edited.id, lang)
  }, [getCategoryDetail, lang, edited])
  
  const formik = useFormik({
    initialValues: {
      id: detail?.id ?? '',
      name: detail?.name ?? '',
      parentId: defaultParentId ? defaultParentId : detail?.parentId ?? '',
      isCategory: true,
      value: detail?.value ?? '',
      languangeId: detail?.languageId ?? lang,
    },
    onSubmit: async (values) => {
      try {
        let payload = {}
  
        if (values.parentId.length === 0) {
          payload = {
            ...values,
            parentId: null,
            isCategory: true,
            value: values.name
          }
        } else {
          payload = {
            ...values,
            isCategory: true,
            value: values.name
          }
        }
        
        if (edited) {
          await updateCategory(edited.id, { ...payload, value: values.value } as ICategoryPayload)
            .then(callback)
        } else {
          await createCategory(payload as ICategoryPayload)
            .then(callback)
        }
  
        modalStore.close()
        formik.resetForm()
        getGrid()
      } catch (e) {
        throw e;
      }
    },
    validationSchema: yup.object({
      id: yup.string(),
      parentId: yup.string(),
      name: yup.string().required(),
      value: yup.string().when('id', (id) => !!id ? yup.string().required() : yup.string()),
      isCategory: yup.boolean().oneOf([true], 'Must be category')
    }),
    enableReinitialize: true,
  })
  const { handleChange, handleSubmit, values, errors, isSubmitting } = formik
  
  useEffect(() => {
    return () => {
      clearCategoryDetail()
    }
  }, [clearCategoryDetail])
  
  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ maxWidth: '500px', width: '300px' }}>
      <Typography fontWeight={600} fontSize={20} color="black" mb={2}>{edited ? 'Edit' : 'Create'} category</Typography>
      {detailLoading ?
        <Box display="flex" justifyContent="center">
          <CircularProgress size={24} />
        </Box>
      : (
        <Stack mb={2} gap={2}>
          <TextField
            name="name"
            required
            disabled={!!edited}
            label="Name"
            onChange={handleChange}
            value={values.name}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            name="value"
            required
            label="Value"
            onChange={handleChange}
            value={values.value}
            error={!!errors.value}
            helperText={errors.value}
          />
          <CategorySelect formik={formik} />
          <SelectLanguage onChange={(val) => setLang(val)} value={lang} disabled={languageLoading} />
        </Stack>
      )}
      <Stack direction="row" gap={1} justifyContent="flex-end">
        <Button color="inherit" type="button" onClick={() => modalStore.close()}>Cancel</Button>
        <LoadingButton loading={isSubmitting} variant="contained" type="submit" disabled={detailLoading}>Save</LoadingButton>
      </Stack>
    </Box>
  )
})