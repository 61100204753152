import { Button, Checkbox, Chip, IconButton, Stack, Tooltip } from "@mui/material"
import { useStore } from "../../app/stores/store"
import { useEffect } from "react"
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium"
import { observer } from "mobx-react-lite"
import { format } from "date-fns"
import { history } from "../.."
import UnpublishModal from "./UnpublishModal"
import { convertUTCtoGMTplus7 } from "../../utils/convertUTCtoGMTplus7"
import { Visibility } from "@mui/icons-material"

const PagePublishDataGrid = () => {
  const { pagePublishStore, modalStore } = useStore()
  const { getGrid, grid, gridLoading, params, setParams } = pagePublishStore
  const { isCreator, isSuperAdmin, isPublisher } = useStore().accountStore
  
  useEffect(() => {
    getGrid()
  }, [getGrid])
  
  const publishButtonEnabled = isPublisher || isCreator || isSuperAdmin

  return (
    <StyledDataGridPremium
      columns={[
        {
          field: 'action',
          headerName: '',
          width: 150,
          renderCell: (params) => (
            <Stack direction="row" gap={1}>
              <Tooltip title="View">
                <span>
                  <IconButton onClick={() => history.push(`/pages/form/${params.row.pageId}?publish=true&disabled=true`)} size="small">
                    <Visibility fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              {
                params.row.status === 'PUBLISHED' ? (
                  <Button disabled={!publishButtonEnabled} sx={{ width: 100 }} variant="outlined" color="inherit" size="small" onClick={() => modalStore.open(<UnpublishModal pageId={params.row.pageId} />)}>Unpublish</Button>
                ) : (
                  <Button disabled={!publishButtonEnabled} sx={{ width: 100 }} variant="contained" size="small" onClick={() => history.push(`/pages/publish/${params.row.pageId}`)}>Publish</Button>
                )
              }
            </Stack>
          )
        },
        {
          field: 'pageName',
          headerName: 'Page Name',
          flex: 1,
          minWidth: 200,
        },
        {
          field: 'publisherByName',
          headerName: 'Publisher name',
          width: 200,
        },
        {
          field: 'publisherOn',
          headerName: 'Published on',
          renderCell: (params) => <>{params.row.publisherOn ? format(convertUTCtoGMTplus7(params.row.publisherOn), 'dd MMM yyyy, HH:mm') : '-'}</>,
          width: 200,
        },
        {
          field: 'status',
          headerName: 'Status',
          renderCell: params => params.row.status ? <Chip label={params.row.status} /> : '-',
          width: 200,
        },
        {
          field: 'isScheduller',
          headerName: 'Scheduler active?',
          width: 200,
          renderCell: params => <Checkbox disabled checked={params.row.isScheduller} />
        },
        {
          field: 'schedullerTime',
          headerName: 'Publishing schedule',
          renderCell: params => params.row.schedullerTime ? format(convertUTCtoGMTplus7(params.row.schedullerTime), 'dd MMM yyyy, HH:mm') : '-',
          width: 200,
        },
      ]}
      loading={gridLoading}
      getRowId={row => row.pageId}
      rows={grid.data}
      rowCount={grid.rowCount}
      page={params.currentPage}
      paginationMode={"server"}
      onPageChange={v => setParams('currentPage', v)}
      pageSize={params.pageSize}
      onPageSizeChange={v => setParams('pageSize', v)}
      pagination
      autoHeight
    />
  )
}

export default observer(PagePublishDataGrid)