import { observer } from "mobx-react-lite"
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium"
import { useStore } from "../../app/stores/store"
import { useEffect } from "react"
import { IconButton, Stack, Tooltip } from "@mui/material"
import { DeleteRounded, EditRounded } from "@mui/icons-material"
import { CreateCategoryModal } from "./CreateCategoryModal"
import DeleteCategoryModal from "./DeleteCategoryModal"

const ContentTypeCategoryGrid = () => {
  const { contentTypeCategoryStore, modalStore } = useStore()
  const { getGrid, grid, params, gridLoading, setParams } = contentTypeCategoryStore
  
  useEffect(() => {
    getGrid()
  }, [getGrid])

  return (
    <StyledDataGridPremium 
      loading={gridLoading}
      columns={[
        {
          field: 'action',
          headerName: '',
          renderCell: (params) => (
            <Stack direction="row" gap={1}>
              <Tooltip title="Edit">
                <span>
                  <IconButton color="info" size="small" onClick={() => modalStore.open(<CreateCategoryModal edited={params.row as any} />)}>
                    <EditRounded fontSize="small" /> 
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Delete">
                <span>
                  <IconButton color="error" size="small" onClick={() => modalStore.open(<DeleteCategoryModal id={params.row.id} />)}>
                    <DeleteRounded fontSize="small" /> 
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          )
        },
        {
          field: 'name',
          headerName: 'Name',
          flex: 1
        },
        {
          field: 'nameParent',
          headerName: 'Parent',
          flex: 1
        }
      ]}
      rows={grid.data}
      rowCount={grid.rowCount}
      page={params.currentPage}
      pageSize={params.pageSize}
      onPageChange={v => setParams('currentPage', v)}
      onPageSizeChange={v => setParams('pageSize', v)}
      autoHeight
      pagination
      paginationMode="server"
    />
  )
}

export default observer(ContentTypeCategoryGrid)