import {
  Box,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";

const SettingsPage = () => {
  const { pathname } = useLocation();
  const { isSuperAdmin } = useStore().accountStore

  return (
    <Stack height="100%" direction="row">
      <Box
        sx={{
          width: 300,
          borderRight: ({ palette }) => `1px solid ${palette.divider}`,
          height: "100%",
        }}
      >
        <ListItemButton
          sx={{
            backgroundColor: ({ palette }) =>
              pathname === "/settings/change-password"
                ? palette.primary.light
                : "transparent",
          }}
          to="/settings/change-password"
          component={Link}
        >
          <ListItemText>
            <Typography
              sx={{
                color: ({ palette }) =>
                  pathname === "/settings/change-password"
                    ? palette.primary.main
                    : palette.text.secondary,
              }}
            >
              Change Password
            </Typography>
          </ListItemText>
        </ListItemButton>
        {isSuperAdmin && (
          <ListItemButton
            sx={{
              backgroundColor: ({ palette }) =>
                pathname === "/settings/session"
                  ? palette.primary.light
                  : "transparent",
            }}
            to="/settings/session"
            component={Link}
          >
            <Typography
              sx={{
                color: ({ palette }) =>
                  pathname === "/settings/session"
                    ? palette.primary.main
                    : palette.text.secondary,
              }}
            >
              Session
            </Typography>
          </ListItemButton>
        )}
      </Box>
      <Box flex={1} px={"20px"}>
        <Outlet />
      </Box>
    </Stack>
  );
}

export default observer(SettingsPage)