import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { MediaImage } from './MediaImage';
import { MediaVideo } from './MediaVideo';
import { MediaFile } from './MediaFile';
import { IMedia } from '../../app/models/media';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface MediaTileProps {
  onClickMedia?: (media: IMedia) => void;
  onRemoveMedia?: (id: string) => void;
  onEditMedia?: (id: string, media: IMedia) => Promise<void>;
}

export default function MediaTab({ onClickMedia, onRemoveMedia, onEditMedia }: MediaTileProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Image" {...a11yProps(0)} />
          <Tab label="Video" {...a11yProps(1)} />
          <Tab label="File" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <MediaImage onEditMedia={onEditMedia} editable onClickMedia={onClickMedia} onRemoveMedia={onRemoveMedia} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <MediaVideo onEditMedia={onEditMedia} editable onClickMedia={onClickMedia} onRemoveMedia={onRemoveMedia} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <MediaFile onEditMedia={onEditMedia} editable onClickMedia={onClickMedia} onRemoveMedia={onRemoveMedia} />
      </CustomTabPanel>
    </Box>
  );
}