import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useStore } from "../../app/stores/store"
import { useEffect } from "react"


interface SitemapSelectPageProps {
  handleChange: (event: SelectChangeEvent<any>) => void;
  value: string;
  error?: string;
}

const SitemapSelectCategoryContent = ({ handleChange, value, error }: SitemapSelectPageProps) => {
  const { dropdownCategory, dropdownCategoryLoading, getDropdownCategory } = useStore().sitemapStore

  useEffect(() => {
    getDropdownCategory()
  }, [getDropdownCategory])

  return (
    <FormControl fullWidth>
      <InputLabel id="select-content-type-category" required>Select category content</InputLabel>
      <Select
        labelId="select-page-label"
        id="select-content-type-category"
        value={value}
        label="Category content"
        name="contentTypeId"
        disabled={dropdownCategoryLoading}
        onChange={handleChange}
        error={!!error}
      >
        {dropdownCategory.map(item => (
          <MenuItem key={item.value} value={item.value.toLowerCase()}>{item.text}</MenuItem>
        ))}
      </Select>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default observer(SitemapSelectCategoryContent)