import { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import {
  ContentCopyRounded,
  DeleteRounded,
  EditRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { history } from "../..";
import { convertUTCtoGMTplus7 } from "../../utils/convertUTCtoGMTplus7";
import { Helmet } from "react-helmet";

const TemplatePage = () => {
  const {
    getTemplateGrid,
    templateGrid,
    duplicateTemplate,
    duplicateTemplateLoading,
    deleteTemplate,
    deleteTemplateLoading,
    templateGridLoading,
    params,
    setParams,
  } = useStore().templateStore;
  const { modalStore } = useStore();

  useEffect(() => {
    getTemplateGrid();
  }, [getTemplateGrid]);

  const onDelete = (templateId: string) => {
    modalStore.setTitle("Delete Confirmation");
    modalStore.open(
      <Box>
        <Typography fontSize="small">
          Are you sure want to remove this template?
        </Typography>
        <Stack mt={2} direction="row" justifyContent="flex-end" gap={2}>
          <Button onClick={modalStore.close} color="inherit">
            Cancel
          </Button>
          <LoadingButton
            loading={deleteTemplateLoading}
            onClick={() => {
              deleteTemplate(templateId)
                .then(modalStore.close)
                .then(getTemplateGrid);
            }}
            variant="contained"
            color="error"
          >
            Delete
          </LoadingButton>
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>Template | Nusantara Regas</title>
      </Helmet>
      <DashboardLayoutHeader
        pageTitle="Templates"
        breadcrumbs={[
          { label: "templates", href: "/templates" },
          { label: "list", href: "/templates" },
        ]}
      >
        <Link to="/templates/form">
          <Button variant="contained">Add Template</Button>
        </Link>
      </DashboardLayoutHeader>
      <DataGrid
        columns={[
          {
            field: "action",
            headerName: "",
            width: 150,
            renderCell: (params) => (
              <Stack direction="row" gap={0.5}>
                <Tooltip title="Edit">
                  <IconButton color="info" size="small" onClick={() => history.push(`/templates/form/${params.row.id}`)}>
                    <EditRounded fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Duplicate">
                  <IconButton onClick={() => duplicateTemplate(params.row.id).then(getTemplateGrid)} size="small" disabled={duplicateTemplateLoading}>
                    {duplicateTemplateLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <ContentCopyRounded fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => onDelete(params.row.id)}
                    size="small"
                    color="error"
                  >
                    <DeleteRounded fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            ),
          },
          {
            field: "name",
            flex: 1,
            headerName: "Name",
          },
          {
            field: "createdBy",
            width: 250,
            headerName: "Created by",
          },
          {
            field: "createdOn",
            headerName: "Created on",
            width: 250,
            renderCell: (params) =>
              format(convertUTCtoGMTplus7(params.row.createdOn), "dd MMM yyyy - HH:mm"),
          },
        ]}
        loading={templateGridLoading}
        rows={templateGrid.data}
        rowCount={templateGrid.rowCount}
        page={params.currentPage}
        pageSize={params.pageSize}
        onPageChange={(v) => setParams("currentPage", v)}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(v) => setParams("pageSize", v)}
        sx={{ minHeight: 200 }}
        autoHeight
      />
    </>
  );
};

export default observer(TemplatePage);
