import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useFormik } from "formik";
import * as yup from "yup";
import { Add } from "@mui/icons-material";
import { getField } from "../../utils/getField";
import AddMedia from "../media/AddMedia";
import MediaTile from "../media/MediaTile";
import { IMedia } from "../../app/models/media";
import { toJS } from "mobx";
import { useEffect } from "react";
import { MediaImage } from "../media/MediaImage";

interface TimelineModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  description: yup.string(),
  periods: yup.array().of(yup.object({
    period: yup.string().required('Period is required field'),
    description: yup.string(),
    activities: yup.array().of(yup.object({
      title: yup.string().required('Title is required'),
      description: yup.string(),
      mediaId: yup.string().nullable(),
      fileUrl: yup.string().nullable(),
    }))
  })).min(1),
})

type ValidationSchema = yup.InferType<typeof validationSchema>

const TimelineModal = ({ onClose, isOpen }: TimelineModalProps) => {
  const {
    modalStore,
    timelineStore
  } = useStore();
  const { 
    createTimeline,
    getGrid,
    updateTimeline,
    deleteTimeline,
    temp,
    setTemp,
  } = timelineStore
  
  const handlePickMedia = (name: string, media: Omit<IMedia, "type">) => {
    setFieldValue(name + '.mediaId', media.id, true)
    setFieldValue(name + '.fileUrl', media.filePath, true)
    setFieldValue(name + '.fileName', media.nameFile, true)

    modalStore.close();
  };
    
  const handleOpenMediaModal = (name: string) => {
    modalStore.open(
      <Stack gap={2} sx={{ maxHeight: '80vh', minWidth: '80vw', overflow: 'auto' }}>
        <AddMedia callback={(value) => handlePickMedia(name, value)} />
        <MediaImage croppable onClickMedia={(media) => handlePickMedia(name, media)} />
      </Stack>
    );
  };

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    resetForm,
    isSubmitting,
    setFieldValue,
  } = useFormik<ValidationSchema>({
    validationSchema: validationSchema,
    initialValues: {
      title: temp?.title ?? '',
      description: temp?.description ?? '',
      periods: temp?.periods.map(i => ({
        period: i.period,
        description: i.description,
        activities: i.activities.map(t => ({
          title: t.title,
          description: t.description,
          mediaId: t.mediaId,
          fileUrl: t.filePath,
        })),
      })) as any ?? [],
    },
    onSubmit: (values: ValidationSchema) => {
      values = {
        ...values,
        periods: values.periods?.map(period => ({
          ...period,
          activities: period.activities?.map(activity => ({
            ...activity,
            mediaId: !!activity.mediaId ? activity.mediaId : null
          })) ?? []
        })) ?? []
      }
      if (!!temp) {
        updateTimeline(temp.id, values as any).then(() => {
          onClose();
          resetForm();
          getGrid();
          setTemp(null);
        });
      } else {
        createTimeline({
          ...values as any,
        }).then(() => {
          onClose();
          resetForm();
          getGrid();
          setTemp(null);
        });
      }
    },
    enableReinitialize: true,
  });
  
  return (
    <Dialog
      sx={{ ".MuiPaper-root": { maxWidth: 500, width: "100%" } }}
      open={isOpen || !!temp}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle component="div">
          <Typography variant="h3" fontSize={20} fontWeight={600} color="black">
            {!!temp ? "Edit" : "Add"} Timeline
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ pt: 2, gap: 1 }}>
            <TextField
              value={values.title}
              name="title"
              label="Title"
              onChange={handleChange}
              error={!!errors.title}
              helperText={errors.title}
            />
            {values.periods?.map((item, index) => (
              <Stack spacing={2} mt={1} key={`${index}`}>
                <Typography color="black" sx={{ fontWeight: 700 }}>{values.periods?.[index]?.period ?? '-'} period</Typography>
                <TextField
                  fullWidth
                  label="Period"
                  name={`periods[${index}].period`}
                  onChange={({ target }) => setFieldValue(`periods[${index}].period`, target.value)}
                  value={values.periods?.[index]?.period}
                  error={!!(errors.periods?.[index] as any)?.period}
                  helperText={(errors.periods?.[index] as any)?.period}
                />
                <TextField
                  fullWidth
                  label="Description"
                  name={`periods[${index}].description`}
                  onChange={handleChange}
                  value={values.periods?.[index]?.description}
                  error={!!(errors.periods?.[index] as any)?.description}
                  helperText={(errors.periods?.[index] as any)?.description}
                />
                {values.periods?.[index].activities?.map((activity, ix) => (
                  <Stack direction="row" gap={4} key={ix}>
                    <Box width={2} sx={{ borderRadius: 5 }} />
                    <Stack spacing={2} flex={1}>
                      <Typography fontWeight={700} color="black" sx={{ opacity: .8 }}>{values.periods?.[index]?.activities?.[ix]?.title} Activities in {values.periods?.[index]?.period ?? '-'}</Typography>
                      <Stack spacing={2} flex={1}>
                        <TextField 
                          label="Title" 
                          fullWidth 
                          name={`periods[${index}].activities[${ix}].title`}
                          onChange={handleChange}
                          value={values.periods?.[index]?.activities?.[ix]?.title}
                          error={!!(errors.periods?.[index] as any)?.activities?.[ix]?.title}
                          helperText={(errors.periods?.[index] as any)?.activities?.[ix]?.title}
                        />
                        <TextField 
                          label="Description" 
                          fullWidth 
                          name={`periods[${index}].activities[${ix}].description`}
                          onChange={handleChange}
                          value={values.periods?.[index]?.activities?.[ix]?.description}
                          error={!!(errors.periods?.[index] as any)?.activities?.[ix]?.description}
                          helperText={(errors.periods?.[index] as any)?.activities?.[ix]?.description}
                        />
                        <FormControl>
                          {getField({
                            element: 'IMAGE', 
                            props: {
                              name: `periods[${index}].activities[${ix}]`,
                              label: 'Media',
                              value: values.periods?.[index]?.activities?.[ix].mediaId ?? '',
                              preview: values.periods?.[index]?.activities?.[ix].fileUrl ?? '',
                              onClick: () => handleOpenMediaModal(`periods[${index}].activities.[${ix}]`),
                            },
                          })}
                          {(errors.periods?.[index] as any)?.activities?.[ix]?.mediaId && <FormHelperText error>{(errors.periods?.[index] as any)?.activities?.[ix]?.mediaId}</FormHelperText>}
                        </FormControl> 
                      </Stack>
                      <Divider />
                    </Stack>
                  </Stack>
                ))}
                <Stack direction="row" gap={4}>
                  <Box />
                  <Button sx={{ flex: 1 }} onClick={() => setFieldValue(`periods[${index}].activities`, [...values.periods?.[index].activities as any, { title: '', description: '', mediaId: '' }])} variant="outlined" startIcon={<Add />}>Activity</Button>
                </Stack>
                <Divider sx={{ background: 'black' }} />
                <Box sx={{ marginBottom: '20px' }} />
              </Stack>
            ))}
            <Button variant="outlined" onClick={() => setFieldValue('periods', [...values.periods as any, { title: '', description: '', activities: [] }])} color="primary" startIcon={<Add />}>Period</Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => {
              onClose();
              resetForm();
              setTemp(null);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default observer(TimelineModal);
