import { makeAutoObservable, reaction, runInAction } from "mobx";
import { ContentTypeName } from "../config/enum";
import { IContentType, IContentTypeDetail, IContentTypePayload } from "../models/contentType";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { ICategory } from "../models/category";

export class ContentTypeStore {
  contentTypeName = ''
  
  contentType: IContentType[] = []
  contentTypeLoading = false
  
  grid: DataGridResult<IContentType[]> = new DataGridResult([], 0)
  gridLoading = false
  
  detail: IContentTypeDetail | null = null;
  detailLoading = false;

  deleteLoading = false;
  
  params = {
    currentPage: 0,
    pageSize: 25,
  }
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => [this.params.currentPage, this.params.pageSize], () => {
      this.getGrid(this.contentTypeName)
    })
  }

  getContentTypes = async (contentTypeName: ContentTypeName) => {
    this.contentTypeName = contentTypeName
    
    this.contentTypeLoading = true;
    try {
      const res = await agent.ContentType.list(contentTypeName)
      
      runInAction(() => this.contentType = res);
    } finally {
      runInAction(() => this.contentTypeLoading = false);
    }
  }

  getGrid = async (contentTypeName: string) => {
    this.contentTypeName = contentTypeName
    
    this.gridLoading = true;
    try {
      const res = await agent.ContentType.grid(contentTypeName, this.params)

      runInAction(() => this.grid = res)
    } finally {
      runInAction(() => this.gridLoading = false)
    }
  }

  create = async (body: IContentTypePayload) => {
    return await agent.ContentType.create(body)
  }

  update = async (id: string, body: IContentTypePayload) => {
    return await agent.ContentType.update(id, body)
  }

  getDetail = async (contentTypeId: string, languageId: string) => {
    this.detailLoading = true;
    try {
      const res = await agent.ContentType.detail(contentTypeId, languageId)
      
      runInAction(() => this.detail = res)
    } catch (e) {
      runInAction(() => this.detail = null)
    } finally { 
      runInAction(() => this.detailLoading = false)
    }
  }

  resetDetail = () => {
    this.detail = null
  }

  deleteContentType = async (id: string) => {
    this.deleteLoading = true
    try {
      return await agent.ContentType.delete(id)
    } finally {
      runInAction(() => this.deleteLoading = false)
    }
  }

  setParams = (key: keyof typeof this.params, value: number) => {
    this.params[key] = value;
  }

  categoryListLoading = false
  categoryList: ICategory[] = []
  getCategoryList = async (contentTypeName: string) => {
    this.categoryListLoading = true;
    try {
      const result = await agent.ContentType.categoryList(contentTypeName)

      runInAction(() => this.categoryList = result)
      return result;
    } finally {
      runInAction(() => this.categoryListLoading = false)
    }
  }
}