import {makeAutoObservable, reaction, runInAction} from "mobx";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IPagePublish, IPagePublishDetail, IPagePublishPayload, IPageUnpublishPayload } from "../models/pagePublish";
import agent from "../api/agent";
import { PublishStatus } from "../config/enum";

export class PagePublishStore {
  grid: DataGridResult<IPagePublish[]> = new DataGridResult([], 0)
  gridLoading = false

  detail: IPagePublishDetail | null = null
  detailLoading = false
  
  publishLoading = false;
  unpublishLoading = false;
  
  params = {
    currentPage: 0,
    pageSize: 25,
  }
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => [this.params.currentPage, this.params.pageSize], () => this.getGrid())
  }

  getGrid = async () => {
    this.gridLoading = true
    try {
      const res = await agent.PagePublish.getGrid(this.params)

      runInAction(() => this.grid = res)
    } finally {
      runInAction(() => this.gridLoading = false)
    }
  }

  getDetail = async (pageId: string) => {
    this.detailLoading = true;
    try {
      const res = await agent.PagePublish.getDetail(pageId)
      
      runInAction(() => this.detail = res)
    } finally {
      runInAction(() => this.detailLoading = false)
    }
  }

  setParams = (key: keyof typeof this.params, value: number) => {
    this.params[key] = value
  }

  publish = async (status: PublishStatus, body: IPagePublishPayload) => {
    this.publishLoading = true;
    try {
      return await agent.PagePublish.publish(status, body)
    } finally {
      runInAction(() => this.publishLoading = false)
    }
  }

  unpublish = async (pageId: string, body: IPageUnpublishPayload) => {
    this.unpublishLoading = true;
    try {
      return await agent.PagePublish.unpublish(pageId, body)
    } finally {
      runInAction(() => this.unpublishLoading = false)
    }
  }
}