import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
} from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { observer } from "mobx-react-lite";
import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useStore } from "../../app/stores/store";
import { ComponentAttributeType, MediaType } from "../../app/config/enum";
import { PagePayload } from "../../app/models/page";
import { LoadingButton } from "@mui/lab";
import AddMedia from "../media/AddMedia";
import { IMedia } from "../../app/models/media";
import SelectLanguage from "./components/SelectLanguage";
import { useParams, useSearchParams } from "react-router-dom";
import { history } from "../..";
import { PageForm } from "./components/PageForm";
import { LoadingSection } from "../../app/component/LoadingSection";
import { PageFormTemplateFormItem } from "./components/PageFormTemplateFormItem";
import { useFormik } from "formik";
import * as yup from "yup";
import { useQuery } from "../../utils/useQuery";
import { PreviewPage } from "./components/PreviewPage";
import { MediaImage } from "../media/MediaImage";
import { MediaVideo } from "../media/MediaVideo";
import { MediaFile } from "../media/MediaFile";
import { Visibility } from "@mui/icons-material";
import { toJS } from "mobx";

const validationSchema = yup.object({
  name: yup.string().required(),
  slug: yup
    .string()
    .matches(
      /^[a-zA-Z0-9\-]+$/,
      "Format is invalid. Slugs can only be written in lowercase letters and without any symbols except for - (as a replacement for spaces)."
    )
    .required(),
  isDefault: yup.bool(),
  templateId: yup.string().required(),
  // pageComponentAttributesValue: yup.array().of(yup.object({
  //   componentAttributeId: yup.string().required(),
  //   value: yup.lazy((value) => {
  //     if (typeof value === 'string') {
  //       // If the value is a string, validate it as a string
  //       return yup.string().required().min(3); // Example string validation
  //     } else if (value instanceof File) {
  //       // If the value is a File object, validate it as a file
  //       return yup.object().shape({
  //         file: yup
  //           .mixed()
  //           .required('A file is required')
  //           .test('fileType', 'Unsupported file format', (file) => {
  //             // You can perform file type validation here
  //             // For example, check if it's an image
  //             return file && file.type.includes('image/');
  //           }),
  //         // Additional properties like size can be validated here
  //         // For example:
  //         // size: yup.number().max(1024 * 1024, 'File size too large. Maximum size is 1MB'),
  //       });
  //     } else {
  //       // Value is neither a string nor a File, reject
  //       return yup.mixed().notRequired().nullable().test(
  //         'fileOrString',
  //         'Field must be either a string or a file',
  //         (val) => val === undefined || typeof val === 'string' || val instanceof File
  //       );
  //     }
  //   }),
  //   fileUrl: yup.string(),
  // }))
});

const PageFormPage = () => {
  const { templateDetail, templateDetailLoading } = useStore().templateStore;
  const { modalStore, snackbarStore, languageStore, accountStore } = useStore();
  const { language } = languageStore;
  const { isSuperAdmin, isCreator } = accountStore;
  const {
    addPage,
    getPageDetail,
    pageDetail,
    pageDetailLoading,
    clearPageDetail,
    addUpdateDraftLoading,
    addUpdateSubmitLoading,
    updatePage,
  } = useStore().pageStore;
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { id } = useParams();
  const query = useQuery();
  const approval = query.get("approval");
  const publish = query.get("publish");
  const disabled = query.get("disabled");
  const [searchParams, setSearchParams] = useSearchParams();
  const languageId = searchParams.get("languageId");

  const [pageComponentAttributesValue, setPageComponentAttributesValue] =
    useState<PagePayload["pageComponentAttributesValue"]>([]);

  const formik = useFormik({
    initialValues: {
      name: "",
      slug: "",
      isDefault: false,
      templateId: "",
    },
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  });

  const { values, setValues, errors } = formik;

  const handleOpenPreview = useCallback(() => setIsPreviewOpen(true), []);
  const handleClosePreview = useCallback(() => setIsPreviewOpen(false), []);

  const handleOpenMediaModal = (name: string, type: string) => {
    modalStore.open(
      <Stack
        gap={2}
        sx={{ maxHeight: "80vh", minWidth: "80vw", overflow: "auto" }}
      >
        <AddMedia callback={(value) => handlePickMedia(name, value)} />
        {type === MediaType.IMAGE && (
          <MediaImage
            croppable
            onClickMedia={(media) => handlePickMedia(name, media)}
          />
        )}
        {type === MediaType.VIDEO && (
          <MediaVideo onClickMedia={(media) => handlePickMedia(name, media)} />
        )}
        {type === MediaType.FILE && (
          <MediaFile onClickMedia={(media) => handlePickMedia(name, media)} />
        )}
      </Stack>
    );
  };

  const handlePickMedia = useCallback(
    (name: string, media: Omit<IMedia, "type">) => {
      setPageComponentAttributesValue((prev) => {
        return prev.find((i) => i.componentAttributeId === name)
          ? prev.map((i) =>
              i.componentAttributeId === name
                ? { ...i, value: media.id, fileUrl: media.filePath }
                : i
            )
          : [
              ...prev,
              {
                componentAttributeId: name,
                value: media.id,
                fileUrl: media.filePath,
              },
            ];
      });

      modalStore.close();
    },
    [modalStore]
  );

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      type?: string
    ) => {
      if (type === ComponentAttributeType.IMAGE) {
        const files = (event.target as HTMLInputElement).files;

        if (!files) return;

        setPageComponentAttributesValue((prev) => {
          return prev.find((i) => i.componentAttributeId === event.target.name)
            ? prev.map((i) =>
                i.componentAttributeId === event.target.name
                  ? { ...i, value: files[0] }
                  : i
              )
            : [
                ...prev,
                { componentAttributeId: event.target.name, value: files[0] },
              ];
        });
      } else if (
        type === ComponentAttributeType.VIDEO ||
        type === ComponentAttributeType.FILE
      ) {
        const files = (event.target as HTMLInputElement).files;

        if (!files) return;

        setPageComponentAttributesValue((prev) => {
          return prev.find((i) => i.componentAttributeId === event.target.name)
            ? prev.map((i) =>
                i.componentAttributeId === event.target.name
                  ? { ...i, value: files[0] }
                  : i
              )
            : [
                ...prev,
                { componentAttributeId: event.target.name, value: files[0] },
              ];
        });
      } else {
        setPageComponentAttributesValue((prev) => {
          return prev.find((i) => i.componentAttributeId === event.target.name)
            ? prev.map((i) =>
                i.componentAttributeId === event.target.name
                  ? { ...i, value: event.target.value }
                  : i
              )
            : [
                ...prev,
                {
                  componentAttributeId: event.target.name,
                  value: event.target.value,
                },
              ];
        });
      }
    },
    []
  );

  const handleSubmit = async (status: "DRAFT" | "SUBMITTED") => {
    if (!languageId) return;

    if (id)
      await updatePage(id, {
        ...values,
        pageComponentAttributesValue,
        languageId: languageId,
        pageId: id,
        status,
      } as any).then(() => {
        snackbarStore.show("success", "Page updated successfully");
      });
    else
      await addPage({
        ...values,
        languageId: languageId,
        pageComponentAttributesValue,
        status,
      }).then((id) => {
        history.push(`/pages/form/${id}`);
        snackbarStore.show("success", "Page created successfully");
      });
  };

  const canEdit = (isSuperAdmin || isCreator) && disabled !== "true";

  const fetchPage = useCallback(() => {
    if (!!id && !!languageId) {
      getPageDetail(id, languageId).then((res) => {
        setPageComponentAttributesValue(res.pageComponentAttributesValue);
        setValues({
          name: res.name,
          isDefault: res.isDefault,
          slug: res.slug,
          templateId:
            res.templateId === "00000000-0000-0000-0000-000000000000"
              ? values.templateId
                ? values.templateId
                : ""
              : res.templateId,
        });
      });
    } else {
      clearPageDetail()
    }
  }, [id, languageId, getPageDetail, setValues]);

  const renderPreviewPage = useCallback(() => {
    if (templateDetail && pageComponentAttributesValue)
      return (
        <PreviewPage
          pageComponentAttributesValue={pageComponentAttributesValue}
          components={templateDetail.components}
          isOpen={isPreviewOpen}
          onClose={handleClosePreview}
        />
      );
  }, [
    templateDetail,
    pageComponentAttributesValue,
    isPreviewOpen,
    handleClosePreview,
  ]);

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  useEffect(() => {
    setPageComponentAttributesValue((prev) =>
      prev.map((i) => ({
        componentAttributeId: i.componentAttributeId,
        value: "",
        fileUrl: null,
      }))
    );

    if (!languageId) {
      if (Array.isArray(language) && !!language.length) {
        history.push(
          id
            ? `/pages/form/${id}?languageId=${language[0].id}&approval=${approval}&publish=${publish}&disabled=${disabled}`
            : `/pages/form?languageId=${language[0].id}&approval=${approval}&publish=${publish}&disabled=${disabled}`
        );
      }
    }
  }, [languageId, language, id]);

  useEffect(() => {
    if (!!pageComponentAttributesValue?.length) return;

    const result = templateDetail?.components
      ?.map((component) =>
        component.componentAttributes.map((attribute) => ({
          componentAttributeId: attribute.componentAttributesId,
          value: "",
          fileUrl: null,
        }))
      )
      .flat();

    const hasResult = result && result.length > 0;
    const isPageComponentEmpty =
      !pageComponentAttributesValue ||
      pageComponentAttributesValue.length === 0;

    if (isPageComponentEmpty && hasResult) {
      setPageComponentAttributesValue(
        result ?? ([] as PagePayload["pageComponentAttributesValue"])
      );
    }
  }, [templateDetail, pageComponentAttributesValue]);

  const loading = useMemo(() => {
    return templateDetailLoading;
  }, [templateDetailLoading]);

  return (
    <>
      {pageDetailLoading ? (
        <Stack marginBottom={2} alignItems="center">
          <CircularProgress size={20} />
        </Stack>
      ) : (
        pageDetail && (
          <Stack marginBottom={2} gap={1}>
            {pageDetail.notesApproval && (
              <Alert severity="warning">
                <b>Approval note: </b> {pageDetail.notesApproval}
              </Alert>
            )}
            {pageDetail.notesPublisher && (
              <Alert severity="info">
                <b>Publisher note: </b> {pageDetail.notesPublisher}
              </Alert>
            )}
          </Stack>
        )
      )}
      <DashboardLayoutHeader
        pageTitle={
          canEdit ? `${id ? "Edit" : "Create"} Page` : formik.values?.name
        }
        breadcrumbs={[
          { label: "page", href: "/page" },
          { label: "list", href: "/page" },
          { label: "form", href: "/page/form" },
        ]}
      >
        <Box>
          <Stack direction="row" gap={1}>
            <div>
              <Button
                color="inherit"
                onClick={() =>
                  history.push(
                    publish === "true"
                      ? "/pages/publish"
                      : approval === "true"
                      ? "/pages/approval"
                      : "/pages/list"
                  )
                }
              >
                {canEdit ? "Cancel" : "Back"}
              </Button>
            </div>
            <div>
              <LoadingButton
                disabled={!canEdit}
                onClick={() => handleSubmit("DRAFT")}
                variant="outlined"
                loading={addUpdateDraftLoading}
              >
                Save as draft
              </LoadingButton>
            </div>
            <div>
              <LoadingButton
                disabled={!canEdit}
                onClick={() => handleSubmit("SUBMITTED")}
                variant="contained"
                loading={addUpdateSubmitLoading}
              >
                Save
              </LoadingButton>
            </div>
          </Stack>
          <Box mt={2}>
            <SelectLanguage
              onChange={(value) =>
                history.push(
                  id
                    ? `/pages/form/${id}?languageId=${value}&approval=${approval}&publish=${publish}&disabled=${disabled}`
                    : `/pages/form?languageId=${value}&approval=${approval}&publish=${publish}&disabled=${disabled}`
                )
              }
              value={languageId ?? ""}
            />
          </Box>
        </Box>
      </DashboardLayoutHeader>
      <PageForm
        callbackTemplate={() => setPageComponentAttributesValue([])}
        disabled={!canEdit}
        formik={formik}
      />
      {loading ? (
        <LoadingSection />
      ) : (
        <Stack spacing={2}>
          <Divider />
          {templateDetail?.components?.map((component, index) => {
            return (
              <Fragment key={index}>
                <PageFormTemplateFormItem
                  disabled={!canEdit}
                  errors={errors}
                  handleOpenMediaModal={handleOpenMediaModal}
                  values={pageComponentAttributesValue}
                  onChange={handleChange}
                  component={component}
                  index={index}
                />
              </Fragment>
            );
          })}
        </Stack>
      )}
      <Button
        sx={{
          position: "fixed",
          bottom: "50px",
          right: "50px",
          padding: "18px",
          borderRadius: "100px",
        }}
        variant="contained"
        size="small"
        onClick={handleOpenPreview}
        startIcon={<Visibility />}
      >
        PREVIEW
      </Button>
      {renderPreviewPage()}
    </>
  );
};

export default observer(PageFormPage);
