import { observer } from "mobx-react-lite"
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader"
import { useStore } from "../../app/stores/store"
import ContentTypeCategoryGrid from "./ContentTypeCategoryGrid"
import { CreateCategoryModal } from "./CreateCategoryModal"
import { Helmet } from "react-helmet"
import SelectLanguage from "../page/components/SelectLanguage"
import { useEffect, useState } from "react"
import { Box, Stack } from "@mui/material"

const ContentTypeCategoryPage = () => {
  const { modalStore } = useStore()
  
  return (
    <>
      <Helmet>
        <title>Category | Nusantara Regas</title>
      </Helmet>
      <DashboardLayoutHeader
        breadcrumbs={[
          { label: 'content types', href: '/content-types/category/list' },
          { label: 'category', href: '/content-types/category/list' },
          { label: 'list', href: '/content-types/category/list' }
        ]}
        pageTitle="Category"
      >
        <Stack spacing={2}>
          <DashboardLayoutHeader.Button variant="contained" onClick={() => modalStore.open(<CreateCategoryModal />)}>
            Add category
          </DashboardLayoutHeader.Button>
        </Stack>
      </DashboardLayoutHeader>
      <ContentTypeCategoryGrid />
    </>
  )
}

export default observer(ContentTypeCategoryPage)