import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";
import { useStore } from "../../app/stores/store";
import { Dispatch, SetStateAction, useEffect } from "react";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { CheckRounded, DoNotDisturbOffRounded, EditRounded, Visibility } from "@mui/icons-material";
import { history } from "../..";
import { ApprovalResponseType } from "../../app/config/enum";
import { PageApproveRejectTypeValues } from "../../app/models/pageApproval";
import { convertUTCtoGMTplus7 } from "../../utils/convertUTCtoGMTplus7";

interface PageApprovalDataGridProps {
  setApproveRejectState: Dispatch<SetStateAction<PageApproveRejectTypeValues | null>>
}

const PageApprovalDataGrid = ({ setApproveRejectState }: PageApprovalDataGridProps) => {
  const { getApprovalGrid, approvalGrid, approvalGridLoading, params, setParams } =
    useStore().pageApprovalStore;
  const { isSuperAdmin, isCreator, isApprover } = useStore().accountStore
  
  useEffect(() => {
    getApprovalGrid();
  }, [getApprovalGrid]);

  const approveButtonEnabled = (isApprover || isCreator || isSuperAdmin) 
  
  return (
    <StyledDataGridPremium
      columns={[
        {
          field: 'action',
          headerName: '',
          renderCell: (params) => {
            return (
              <Stack direction="row">
                <Tooltip title="View">
                  <span>
                    <IconButton disabled={!approveButtonEnabled} onClick={() => history.push('/pages/form/' + params.row.id + '?approval=true&disabled=true')}>
                      <Visibility />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Approve">
                  <span>
                    <IconButton color="success" disabled={!approveButtonEnabled} onClick={() => setApproveRejectState(({
                      pageId: params.row.id,
                      status: ApprovalResponseType.APPROVED,
                      notes: ''
                    }))}>
                      <CheckRounded />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Reject">
                  <span>
                    <IconButton color="error" onClick={() => setApproveRejectState(({
                      pageId: params.row.id,
                      status: ApprovalResponseType.REJECTED,
                      notes: ''
                    }))}>
                      <DoNotDisturbOffRounded />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            )
          },
          width: 140
        },
        {
          field: "name",
          headerName: "Name",
          minWidth: 250,
          flex: 1
        },
        {
          field: "templateName",
          headerName: "Template",
          width: 250
        },
        {
          field: "createdBy",
          headerName: "Created By",
          width: 160,
        },
        {
          field: "createdOn",
          headerName: "Created On",
          renderCell: (params) =>
            params.row?.createdOn
              ? format(convertUTCtoGMTplus7(params.row.createdOn), "dd MMM yyyy - HH:mm")
              : "-",
          width: 200
        },
      ]}
      loading={approvalGridLoading}
      rows={approvalGrid.data}
      rowCount={approvalGrid.rowCount}
      sx={{ minHeight: 200 }}
      autoHeight
      pagination
      paginationMode={"server"}
      page={params.currentPage}
      onPageChange={(v) => setParams('currentPage', v)}
      onPageSizeChange={v => setParams('pageSize', v)}
    />
  );
};

export default observer(PageApprovalDataGrid);
