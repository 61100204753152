import { Box, Button, CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { forwardRef, useEffect } from "react";
import { ISitemapTree } from "../../app/models/sitemap";
import { observer } from "mobx-react-lite";
import {
  SimpleTreeView,
  TreeItem2,
  TreeItem2Props,
  treeItemClasses,
  UseTreeItem2ContentSlotOwnProps,
  useTreeItem2Utils,
} from "@mui/x-tree-view";
import { Delete, Edit, Sort } from "@mui/icons-material";
import { alpha, styled } from "@mui/system";
import { LoadingButton } from "@mui/lab";

export const SitemapTree = observer(() => {
  const { modalStore } = useStore();
  const { getSitemapTree, sitemapTree, sitemapTreeLoading, setEditedSitemap, setSortedSitemap, deleteSitemap, deleteSitemapLoading } =
    useStore().sitemapStore;

  const handleOpenDeleteModal = (id: string) => {
    modalStore.setTitle("Delete Confirmation");
    modalStore.open(
        <Box>
          <Typography>Are you sure want to delete this item?</Typography>
          <Stack direction="row" gap={2} justifyContent="flex-end" mt={2}>
            <Button onClick={modalStore.close} color="inherit">
              Cancel
            </Button>
            <LoadingButton
              onClick={() =>
                deleteSitemap(id).then(() => {
                  modalStore.close();
                  getSitemapTree();
                })
              }
              color="error"
              variant="contained"
              loading={deleteSitemapLoading}
            >
              Delete
            </LoadingButton>
          </Stack>
        </Box>
    );
  };
    
  useEffect(() => {
    getSitemapTree();
  }, [getSitemapTree]);

  return (
    <Box>
      {sitemapTreeLoading ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      ) : (
        <SimpleTreeView>
          <TreeList root={true} setEditedSitemap={setEditedSitemap} setSortedSitemap={setSortedSitemap} handleOpenDeleteModal={handleOpenDeleteModal} sitemap={sitemapTree} />
        </SimpleTreeView>
      )}
    </Box>
  );
});

const TreeList = ({
  sitemap,
  handleOpenDeleteModal,
  setEditedSitemap,
  setSortedSitemap,
  root
}: {
  sitemap: ISitemapTree[];
  handleOpenDeleteModal: (id: string) => void;
  setEditedSitemap: (item: ISitemapTree) => void;
  setSortedSitemap: (item: ISitemapTree) => void;
  root: boolean;
}) => {
  return (
    <>
      {sitemap?.map((i, x) => (
        <CustomTreeItem
          itemId={i.id}
          key={x}
          label={
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              {i.name}
              <Stack direction="row" spacing={1}>
                <Tooltip title="Sort">
                  <IconButton size="small" onClick={() => setSortedSitemap(i)}>
                    <Sort fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton size="small" onClick={() => setEditedSitemap(i)}>
                    <Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton size="small" onClick={() => handleOpenDeleteModal(i.id)}>
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          }
        >
          {i.child && Array.isArray(i.child) && i.child?.length > 0 && (
            <TreeList root={false} setSortedSitemap={setSortedSitemap} setEditedSitemap={setEditedSitemap} handleOpenDeleteModal={handleOpenDeleteModal} sitemap={i.child} />
          )}
        </CustomTreeItem>
      ))}
    </>
  );
};

const CustomTreeItem = forwardRef(function MyTreeItem(
  props: TreeItem2Props,
  ref: React.Ref<HTMLLIElement>
) {
  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const handleContentClick: UseTreeItem2ContentSlotOwnProps["onClick"] = (
    event
  ) => {
    event.defaultMuiPrevented = true;
    // interactions.handleSelection(event);
  };

  const handleIconContainerClick = (event: React.MouseEvent) => {
    interactions.handleExpansion(event);
  };

  return (
    <StyledTreeItem
      {...props}
      ref={ref}
      slotProps={{
        content: { onClick: handleContentClick },
        iconContainer: { onClick: handleIconContainerClick },
      }}
    />
  );
});

const StyledTreeItem = styled(TreeItem2)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[800]
      : theme.palette.grey[200],

  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: '0.8rem',
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: '50%',
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.primary.main, 0.25)
        : theme.palette.primary.dark,
    color: theme.palette.mode === 'dark' && theme.palette.primary.contrastText,
    padding: theme.spacing(0, 1.2),
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));
