import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { IMedia } from "../../app/models/media";
import { Check, Close, Delete, Edit } from "@mui/icons-material";
import { getFileExtension } from "../../utils/getFileExtentions";
import { getFileNameWithoutExtension } from "../../utils/getFileNameWithoutExtention";

interface MediaVideoProps {
  onClickMedia?: (media: IMedia) => void;
  onRemoveMedia?: (id: string) => void;
  onEditMedia?: (id: string, value: IMedia) => Promise<void>;
  editable?: boolean;
}

export const MediaVideo = observer(
  ({ onClickMedia, onRemoveMedia, editable, onEditMedia }: MediaVideoProps) => {
    const {
      getVideoGrid, videoGrid, videoGridLoading, videoGridParams, setVideoParams, editMediaLoading,
    } = useStore().mediaStore;
    const totalPage = Math.ceil(videoGrid.rowCount / videoGridParams.pageSize);

    const [edited, setEdited] = useState<IMedia & { ext: string }>();

    useEffect(() => {
      getVideoGrid();
    }, [getVideoGrid]);

    return (
      <Box>
        <Grid container spacing={2}>
          {videoGridLoading ? (
            <Grid item xs={12} display="flex" justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            videoGrid.data.map((i) => (
              <Grid
                position="relative"
                overflow="hidden"
                item
                xs={3}
                key={i.id}
              >
                {edited?.id === i.id ? (
                  <Stack
                    direction="row"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      position: "absolute",
                      zIndex: 2,
                      top: 0,
                      right: 0,
                    }}
                  >
                    <IconButton
                      onClick={() => setEdited(undefined)}
                      size="small"
                      disabled={editMediaLoading}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        let payload = { ...edited };
                        payload.nameFile = payload.nameFile + "." + payload.ext;
                        delete (payload as any).ext;

                        onEditMedia && onEditMedia(i.id, payload).then(() => setEdited(undefined));
                      }}
                      size="small"
                      color="success"
                      disabled={editMediaLoading}
                    >
                      {editMediaLoading ? <CircularProgress size={14} /> : <Check fontSize="small" />}
                    </IconButton>
                  </Stack>
                ) : (
                  (!!onRemoveMedia || !!editable) && (
                    <Stack
                      direction="row"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: 2,
                        position: "absolute",
                        zIndex: 2,
                        top: 0,
                        right: 0,
                      }}
                    >
                      {!!editable && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            setEdited({
                              ...i,
                              ext: getFileExtension(i.nameFile) ?? "",
                              nameFile: getFileNameWithoutExtension(i.nameFile),
                            })
                          }
                        >
                          <Edit fontSize="small" color="info" />
                        </IconButton>
                      )}
                      {!!onRemoveMedia && (
                        <IconButton
                          size="small"
                          onClick={() => onRemoveMedia(i.id)}
                        >
                          <Delete fontSize="small" color="error" />
                        </IconButton>
                      )}
                    </Stack>
                  )
                )}
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => onClickMedia && onClickMedia(i)}
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <video style={{ objectFit: 'contain', height: 200 }} src={i.filePath} />
                </Button>
                <TextField
                  sx={{ mb: 1 }}
                  fullWidth
                  InputProps={{
                    endAdornment: edited?.id === i.id ? "." + edited?.ext : "",
                  }}
                  disabled={edited?.id !== i.id}
                  value={edited?.id === i.id ? edited?.nameFile : i.nameFile}
                  size="small"
                  onChange={(e) =>
                    setEdited({ ...edited!, nameFile: e.target.value })
                  }
                />
              </Grid>
            ))
          )}
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={5} alignItems="center" gap={3}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography fontSize={"14px"}>Row per page:</Typography>
            <Select
              variant="standard"
              labelId="row-per-page-label"
              id="row-per-page-label"
              value={videoGridParams.pageSize}
              label="Row per page"
              onChange={({ target }) =>
                setVideoParams("pageSize", Number(target.value))
              }
            >
              {[12, 24, 48].map((i) => (
                <MenuItem value={i} key={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Pagination
            onChange={(event, page) => setVideoParams("currentPage", page - 1)}
            page={videoGridParams.currentPage + 1}
            count={!!totalPage ? totalPage : 1}
          />
        </Box>
      </Box>
    );
  }
);
