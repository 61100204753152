import { Box, Stack, Typography } from "@mui/material";
import { ComponentsDetail } from "../../../app/models/template";
import { PageFormTemplateFormField } from "./PageFormTemplateFormField";
import { ChangeEvent } from "react";
import { PagePayload } from "../../../app/models/page";
import ContentTypeSelectCategory from "../../contentType/components/ContentTypeSelectCategory";
import { toJS } from "mobx";
import { PageFormTemplateFormItemCategory } from "./PageFormTemplateFormItemCategory";
import { PageFormTemplateFormItemTimeline } from "./PageFormTemplateFormItemTimeline";

interface PageFormTemplateFormProps {
  component: ComponentsDetail;
  index: number;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: string
  ) => void;
  values: PagePayload["pageComponentAttributesValue"];
  handleOpenMediaModal: (name: string, type: string) => void;
  errors: any;
  disabled?: boolean;
}

export const PageFormTemplateFormItem = ({
  component,
  index,
  onChange,
  values,
  handleOpenMediaModal,
  errors,
  disabled,
}: PageFormTemplateFormProps) => {
  return (
    <Box
      sx={{
        border: ({ palette }) => `1px solid ${palette.divider}`,
        borderRadius: ({ shape }) => shape.borderRadius + "px",
        p: 2,
      }}
      key={component.componentId + "-" + index}
    >
      <Typography mb={2.5} fontWeight={600} color="black" fontSize="large">
        {component.name}
      </Typography>
      <Stack spacing={1.5}>
        {component.componentAttributes?.map((componentAttr) => {
          return !!component.contentTypeId &&
            component.type === "COMBINATION" ? (
            componentAttr.name === "Category" ? (
              <PageFormTemplateFormItemCategory
                error=""
                values={values}
                name={componentAttr.componentAttributesId}
                contentTypeId={component.contentTypeId}
                handleChange={(e) => onChange(e as any, "TEXT")}
                componentAttr={componentAttr}
                disabled={disabled}
              />
            ) : (
              <Box key={componentAttr.componentAttributesId}>
                <PageFormTemplateFormField
                  disabled={disabled}
                  values={values}
                  onChange={onChange}
                  type={componentAttr.type}
                  name={componentAttr.name}
                  id={componentAttr.componentAttributesId}
                  handleOpenMediaModal={handleOpenMediaModal}
                />
              </Box>
            )
          ) : !!component.contentTypeId ? (
            <PageFormTemplateFormItemCategory
              error=""
              values={values}
              name={componentAttr.componentAttributesId}
              contentTypeId={component.contentTypeId}
              handleChange={(e) => onChange(e as any, "TEXT")}
              componentAttr={componentAttr}
              disabled={disabled}
            />
          ) : component.type === "TIMELINE" ||
            component.name.toLowerCase() === "timeline" ? (
            <PageFormTemplateFormItemTimeline
              error=""
              values={values}
              name={componentAttr.componentAttributesId}
              handleChange={(e) => onChange(e as any, "TEXT")}
              componentAttr={componentAttr}
              disabled={disabled}
            />
          ) : (
            <Box key={componentAttr.componentAttributesId}>
              <PageFormTemplateFormField
                disabled={disabled}
                values={values}
                onChange={onChange}
                type={componentAttr.type}
                name={componentAttr.name}
                id={componentAttr.componentAttributesId}
                handleOpenMediaModal={handleOpenMediaModal}
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
