import { makeAutoObservable, reaction, runInAction } from "mobx";
import { DataGridResult, IDataGridParams } from "../models/materialUI/dataGrid";
import { IPage, IPageDetail, PagePayload } from "../models/page";
import agent from "../api/agent";

export class PageStore {
  pageGrid: DataGridResult<IPage[]> = new DataGridResult([], 0)
  pageGridLoading = false;
  pageDetail: IPageDetail | null = null
  pageDetailLoading = false

  deletePageLoading = false
  addUpdateSubmitLoading = false
  addUpdateDraftLoading = false
  
  params: IDataGridParams = {
    currentPage: 0,
    pageSize: 25,
  }
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => [this.params.currentPage, this.params.pageSize], () => this.getPageGrid())
  }

  getPageGrid = async () => {
    this.pageGridLoading = true
    try {
      const res = await agent.Pages.listGrid(this.params)
      
      runInAction(() => this.pageGrid = res)
    } finally {
      runInAction(() => this.pageGridLoading = false)
    }
  }

  addPage = async (body: PagePayload) => {
    if (body.status === 'SUBMITTED') {
      this.addUpdateSubmitLoading = true
    } else {
      this.addUpdateDraftLoading = true;
    }
    try {
      return await agent.Pages.create(body)
    } finally {
      runInAction(() => {
        this.addUpdateSubmitLoading = false
        this.addUpdateDraftLoading = false;
      })
    }
  }

  getPageDetail = async (pageId: string, languageId: string) => {
    console.log(languageId)
    
    this.pageDetailLoading = true
    try {
      const res = await agent.Pages.detail(pageId, languageId)

      runInAction(() => this.pageDetail = res)

      return res;
    } finally {
      runInAction(() => this.pageDetailLoading = false)
    }
  }

  deletePage = async (pageId: string) => {
    this.deletePageLoading = true;
    try {
      await agent.Pages.delete(pageId)
    } finally {
      runInAction(() => this.deletePageLoading = false)
    }
  }
  
  setParams = (key: keyof typeof this.params, value: number) => {
    this.params[key] = value;
  }

  clearPageDetail = () => this.pageDetail = null

  updatePage = async (pageId: string, body: PagePayload) => {
    this.addUpdateSubmitLoading = true
    this.addUpdateDraftLoading = true;

    try {
      return await agent.Pages.update(pageId, body)
    } finally {
      runInAction(() => {
        this.addUpdateSubmitLoading = false
        this.addUpdateDraftLoading = false;
      })
    }
  }
}