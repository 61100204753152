import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import { DeleteRounded, EditRounded, Visibility } from "@mui/icons-material";
import { history } from "../../..";
import DeletePageModal from "./DeletePageModal";
import { convertUTCtoGMTplus7 } from "../../../utils/convertUTCtoGMTplus7";
import { PageStatus } from "../../../app/config/enum";

const PageDataGrid = () => {
  const { getPageGrid, pageGridLoading, pageGrid, params, setParams } =
    useStore().pageStore;
  const { modalStore, accountStore } = useStore();
  const { isSuperAdmin, isCreator } = accountStore;

  const handleDelete = (id: string) =>
    modalStore.open(<DeletePageModal id={id} />);

  useEffect(() => {
    getPageGrid();
  }, [getPageGrid]);

  const canEdit = isSuperAdmin || isCreator;

  return (
    <>
      <StyledDataGridPremium
        columns={[
          {
            field: "action",
            headerName: "",
            minWidth: 140,
            renderCell: (params) => {
              return (
                <Stack direction="row">
                  <Tooltip title="View">
                    <span>
                      <IconButton
                        size="small"
                        color="inherit"
                        onClick={() =>
                          history.push(
                            "/pages/form/" + params.row.id + "?disabled=true"
                          )
                        }
                      >
                        <Visibility fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <span>
                      <IconButton
                        size="small"
                        disabled={
                          params.row.status === PageStatus.SUBMITTED ||
                          params.row.status === PageStatus.PUBLISHED ||
                          params.row.status === PageStatus.APPROVED ||
                          !canEdit
                        }
                        color="info"
                        onClick={() =>
                          history.push("/pages/form/" + params.row.id)
                        }
                      >
                        <EditRounded fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <span>
                      <IconButton
                        size="small"
                        color="error"
                        disabled={
                          params.row.status === PageStatus.SUBMITTED ||
                          params.row.status === PageStatus.ON_SCHEDULLED ||
                          params.row.status === PageStatus.PUBLISHED ||
                          // params.row.status === PageStatus.UNPUBLISHED ||
                          params.row.status === PageStatus.APPROVED ||
                          !canEdit
                        }
                        onClick={() => handleDelete(params.row.id)}
                      >
                        <DeleteRounded fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Stack>
              );
            },
          },
          {
            field: "name",
            headerName: "Name",
            minWidth: 250,
            width: 250,
          },
          {
            field: "templateName",
            headerName: "Template",
            width: 250,
          },
          {
            field: "status",
            headerName: "Status",
            width: 140,
            renderCell: (params) => (
              <Chip
                color={
                  (params.row.status === PageStatus.APPROVED && "success") ||
                  (params.row.status === PageStatus.DRAFT && "warning") ||
                  (params.row.status === PageStatus.ON_SCHEDULLED && "default") ||
                  (params.row.status === PageStatus.PUBLISHED && "primary") ||
                  (params.row.status === PageStatus.REJECTED && "error") ||
                  (params.row.status === PageStatus.SUBMITTED && "default") ||
                  (params.row.status === PageStatus.UNPUBLISHED && "default") ||
                  "default"
                }
                label={params.row.status}
              />
            ),
          },
          {
            field: "notesApproval",
            headerName: "Approval Notes",
            minWidth: 120,
          },
          {
            field: "notesPublisher",
            headerName: "Publisher Notes",
            minWidth: 120,
          },
          {
            field: "createdBy",
            headerName: "Created By",
            width: 120,
          },
          {
            field: "createdOn",
            headerName: "Created On",
            width: 180,
            renderCell: (params) =>
              params.row?.createdOn
                ? format(
                    convertUTCtoGMTplus7(params.row.createdOn),
                    "dd MMM yyyy - HH:mm"
                  )
                : "-",
          },
        ]}
        loading={pageGridLoading}
        rows={pageGrid.data}
        rowCount={pageGrid.rowCount}
        sx={{ height: 600 }}
        pagination
        paginationMode={"server"}
        page={params.currentPage}
        onPageChange={(v) => setParams("currentPage", v)}
        onPageSizeChange={(v) => setParams("pageSize", v)}
      />
    </>
  );
};

export default observer(PageDataGrid);
