import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useStore } from "../../app/stores/store"
import { useEffect } from "react";
import { toJS } from "mobx";


interface SitemapSelectPageProps {
  handleChange: (event: SelectChangeEvent<any>) => void;
  value: string;
  error?: string;
}

const SitemapSelectParent = ({ handleChange, value, error }: SitemapSelectPageProps) => {
  const { dropdownSitemap, dropdownSitemapLoading, getDropdownSitemap } = useStore().sitemapStore

  useEffect(() => {
    getDropdownSitemap()
  }, [getDropdownSitemap])
  
  return (
    <FormControl fullWidth>
      <InputLabel id="select-parent-label" shrink>Parent</InputLabel>
        <Select
          labelId="select-parent-label"
          id="select-parent"
          value={value}
          displayEmpty
          name="parentId"
          label="Parent"
          disabled={dropdownSitemapLoading}
          onChange={handleChange}
          error={!!error}
        >
          <MenuItem value={''}>{'--- Select ---'}</MenuItem>
          {dropdownSitemap.map(item => (
            <MenuItem key={item.value} value={item.value.toLowerCase()}>{item.text}</MenuItem>
          ))}
        </Select>
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default observer(SitemapSelectParent)