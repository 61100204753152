import { Button, Checkbox, Chip, IconButton, Stack, Tooltip } from "@mui/material"
import { useStore } from "../../app/stores/store"
import {useEffect, useMemo} from "react"
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium"
import { observer } from "mobx-react-lite"
import { format } from "date-fns"
import { history } from "../.."
import UnpublishModal from "./UnpublishModal"
import { convertUTCtoGMTplus7 } from "../../utils/convertUTCtoGMTplus7"
import { Edit, Visibility } from "@mui/icons-material"

const ContentTypePublishDataGrid = () => {
  const { contentTypePublishStore, accountStore, modalStore } = useStore()
  const { getGrid, grid, gridLoading, params, setParams } = contentTypePublishStore
  const { isSuperAdmin, isCreator, isPublisher } = accountStore

  console.log(grid)

  useEffect(() => {
    getGrid()
  }, [getGrid])
  
  const allowPublish = isPublisher || isSuperAdmin || isCreator

  const rows = useMemo(() => grid.data, [grid.data])
  const rowCount = useMemo(() => grid.rowCount, [grid.rowCount])
  const currentPage = useMemo(() => params.currentPage, [params.currentPage])
  const pageSize = useMemo(() => params.pageSize, [params.pageSize])

  return (
    <StyledDataGridPremium 
      columns={[
        {
          field: 'action',
          headerName: '',
          width: 160,
          renderCell: (params) => (
            <Stack direction="row" gap={1}>
              <Tooltip title="View">
                <span>
                  <IconButton onClick={() => history.push(`/content-types/${params.row.contentTypeId}/form/${params.row.contentTypeValueId}?name=${params.row.contentTypeName}&disabled=true`)} size="small">
                    <Visibility fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              {
                params.row.status === 'PUBLISHED' ? (
                  <Button disabled={!allowPublish} sx={{ width: 100 }} variant="outlined" color="inherit" size="small" onClick={() => modalStore.open(<UnpublishModal contentTypeId={params.row.contentTypeValueId} />)}>Unpublish</Button>
                ) : (
                  <Button disabled={!allowPublish} sx={{ width: 100 }} variant="contained" size="small" onClick={() => history.push(`/content-types/publish/${params.row.contentTypeValueId}`)}>Publish</Button>
                )
              }
            </Stack>
          )
        },
        {
          field: 'contentTypeName',
          headerName: 'Name',
          flex: 1,
          minWidth: 200,
          renderCell: (params) => {
            return params.row.contentTypeName + ' - ' + params.row.name;
          }
        },
        {
          field: 'publisherByName',
          headerName: 'Publisher name',
          width: 200,
        },
        {
          field: 'publisherOn',
          headerName: 'Published on',
          renderCell: (params) => <>{format(convertUTCtoGMTplus7(params.row.publisherOn), 'dd MMM yyyy - HH:mm')}</>,
          width: 200
        },
        {
          field: 'status',
          headerName: 'Status',
          renderCell: params => params.row.status ? <Chip label={!!params.row.status ? params.row.status : 'SUBMITTED'} /> : "-",
          width: 170
        },
        {
          field: 'isScheduller',
          headerName: 'Scheduler active?',
          width: 200,
          renderCell: params => <Checkbox disabled checked={params.row.isScheduller} />
        },
      ]}
      getRowId={row => row.contentTypeValueId}
      rows={rows}
      rowCount={rowCount}
      page={currentPage}
      onPageChange={v => setParams('currentPage', v)}
      pageSize={pageSize}
      onPageSizeChange={v => setParams('pageSize', v)}
      pagination
      paginationMode="server"
      autoHeight
      loading={gridLoading}
    />
  )
}

export default observer(ContentTypePublishDataGrid)
