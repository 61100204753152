import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import SitemapSelectPage from "./SitemapSelectPage";
import { useFormik } from "formik";
import * as yup from "yup";
import SitemapSelectParent from "./SitemapSelectParent";
import SitemapSelectCategoryContent from "./SitemapSelectCategory";
import { ISitemapPayload } from "../../app/models/sitemap";
import { toJS } from "mobx";

interface SitemapModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SitemapModal = ({ onClose, isOpen }: SitemapModalProps) => {
  const {
    createSitemap,
    getSitemapGrid,
    updateSitemap,
    editedSitemap,
    setEditedSitemap,
    getSitemapTree,
  } = useStore().sitemapStore;

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    resetForm,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    validationSchema: yup.object({
      pageId: yup
        .string()
        .when("isContentType", (val) =>
          !val ? yup.string().required("Page is required") : yup.string()
        ),
      parentId: yup.string(),
      contentTypeId: yup
        .string()
        .when("isContentType", (val) =>
          val ? yup.string().required("Content Type is required") : yup.string()
        ),
      isContentType: yup.bool(),
    }),
    initialValues: {
      pageId: editedSitemap?.pageId ?? "",
      parentId: editedSitemap?.parentId ?? "",
      contentTypeId: (editedSitemap as any)?.contentTypeId ?? "",
      isContentType: (editedSitemap as any)?.isContentType ?? "",
    },
    onSubmit: (values) => {
      let payload: Omit<ISitemapPayload, "parentId"> | undefined = undefined;

      if (values.isContentType) {
        payload = {
          contentTypeId: values.contentTypeId,
          isContentType: true,
        };
      } else {
        payload = {
          pageId: values.pageId,
          isContentType: false,
        };
      }

      if (!!editedSitemap) {
        updateSitemap(editedSitemap.id, {
          ...payload,
          parentId: !!values.parentId ? values.parentId : null,
        }).then(() => {
          onClose();
          resetForm();
          getSitemapGrid();
          getSitemapTree();
          setEditedSitemap(null);
        });
      } else {
        createSitemap({
          ...payload,
          parentId: !!values.parentId ? values.parentId : null,
        }).then(() => {
          onClose();
          resetForm();
          getSitemapGrid();
          getSitemapTree();
        });
      }
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      sx={{ ".MuiPaper-root": { maxWidth: 500, width: "100%" } }}
      open={isOpen || !!editedSitemap}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle>
          <Typography variant="h3" fontSize={20} fontWeight={600} color="black">
            {!!editedSitemap ? "Edit" : "Add"} Sitemap
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ pt: 2, gap: 1 }}>
            <SitemapSelectParent
              error={errors.parentId}
              value={values.parentId}
              handleChange={handleChange}
            />
            <FormControlLabel
              value={values.isContentType}
              onChange={({ target }: any) =>
                setFieldValue("isContentType", target.checked)
              }
              control={
                <Switch checked={values.isContentType} defaultChecked={values.isContentType || false} />
              }
              label="Is content type"
            />
            {values.isContentType ? (
              <SitemapSelectCategoryContent
                error={errors.contentTypeId as any}
                value={values.contentTypeId}
                handleChange={handleChange}
              />
            ) : (
              <SitemapSelectPage
                error={errors.pageId}
                value={values.pageId}
                handleChange={handleChange}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => {
              onClose();
              setEditedSitemap(null);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default observer(SitemapModal);
