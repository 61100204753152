import { makeAutoObservable, reaction, runInAction } from "mobx";
import { ICategory, ICategoryDropdown, ICategoryPayload } from "../models/category";
import { DataGridResult } from "../models/materialUI/dataGrid";
import agent from "../api/agent";

export class ContentTypeCategoryStore {
  grid: DataGridResult<ICategory[]> = new DataGridResult([], 0)
  gridLoading = false;
  
  dropdown: ICategoryDropdown[] = []
  dropwodnLoading = false;
  
  detail: ICategory | null = null
  detailLoading: boolean = false;
  
  deleteLoading = false
  
  params = {
    currentPage: 0,
    pageSize: 25,
  }
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => [this.params.currentPage, this.params.pageSize], () => this.getGrid())
  }

  getGrid = async () => {
    this.gridLoading = true
    try {
      const res = await agent.ContentTypeCategory.getGrid(this.params)
      runInAction(() => this.grid = res)
    } finally {
      runInAction(() => this.gridLoading = false)
    }
  }

  getCategoryDetail = async (id: string, languageId: string) => {
    this.detailLoading = true;
    try {
      const res = await agent.ContentTypeCategory.detail({ id, languageId })
      runInAction(() => this.detail = res)
    } finally {
      runInAction(() => this.detailLoading = false)
    }
  }

  clearCategoryDetail = () => {
    this.detail = null;
  }

  createCategory = async (body: ICategoryPayload) => {
    return await agent.ContentTypeCategory.create(body);
  }

  updateCategory = async (id: string, body: ICategoryPayload) => {
    return await agent.ContentTypeCategory.update(id, body);
  }

  deleteCategory = async (id: string) => {
    this.deleteLoading = true;
    try {
      return await agent.ContentTypeCategory.delete(id); 
    } finally {
      runInAction(() => this.deleteLoading = false)
    }
  }

  getDropdown = async () => {
    this.dropwodnLoading = true;
    try {
      const res = await agent.ContentTypeCategory.dropdown()

      runInAction(() => this.dropdown = res)
    } finally {
      runInAction(() => this.dropwodnLoading = false)
    }
  }

  setParams = (key: keyof typeof this.params, value: number) => {
    this.params[key] = value;
  }
}